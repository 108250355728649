import { apply, isSupported } from '@oddbird/popover-polyfill/fn'
import { tx } from '@transifex/native'
import { createRoot } from 'react-dom/client'
import { getValueFromLocalStorage } from './core-web/browser-storage'
import { initInstrumentation } from './core-web/instrumentation'
import { isDemoIframe } from './core-web/window'

tx.init({
  // Public token
  token: '1/7ee196536aa38ab4b307a8d945e3527e517ddb44',

  // Only load translations for the login pages (Patient, Companion) to reduce initial bundle size
  filterTags: 'login',
})

// https://mswjs.io/docs/recipes/deferred-mounting
async function enableMocking() {
  const url = new URL(window.location.href)

  // Do not load mock handlers when in iframe or on demo page - will handle without MSW
  if (isDemoIframe() || url.pathname.startsWith('/demo')) {
    return null
  }

  if (
    // if we're in test mode, we always enable mocking
    // except for the demo page, as it has its own mocking setup
    import.meta.env.MODE === 'test'
  ) {
    const { setupMocks } = await import('./mocks')

    return setupMocks()
  }

  return null
}

// Polyfill Popover (Safari <17) https://caniuse.com/?search=popover
if (!isSupported()) {
  apply()
}

/**
 * We sometimes see errors where a module fails to load.
 * From what we can observe in Datadog, these are always occurring
 * in the background during a preload, and are not visible to the user.
 *
 * We've verified that during a deployment, the new assets are always
 * present in the bucket before updating our index.html and version.json.
 *
 * We also never remove the old assets from the bucket, so they should
 * always be available.
 *
 * As such, this is unlikely to have a user-visible impact, but it's
 * filling up our logs with noise and triggering alerts.
 *
 * See https://github.co/vitejs/vite/pull/12084
 *
 * A more comprehensive fix can be found here:
 * https://github.com/vital-software/katoa/pull/18217,
 *
 * At the time of writing (2024-06-06) it requires modifying Vite internals,
 * so we're using this workaround to suppress the errors for now
 */
window.addEventListener('vite:preloadError', (event) => {
  if (!event || !('payload' in event)) {
    return
  }

  const payload = (event as unknown as Error & { payload?: Error }).payload

  if (
    payload?.message &&
    (payload.message.includes('Failed to fetch dynamically imported module') ||
      payload.message.includes('Importing a module script failed'))
  ) {
    // Prevent Vite from throwing the error
    event.preventDefault()
  }
})

/**
 * Returns the initial language based on the browser language, or defaults to English.
 */
const initialLanguage = () => {
  // Get stored language
  const storedLanguage = getValueFromLocalStorage<string>('language')

  // TODO: We should get the language from the `index.html` file, as it should be stored against the `authCode`
  // Lookup saved language from local storage, or the browser language, or default to English if the browser language is not available
  const defaultLanguage = storedLanguage ?? window.navigator?.language ?? 'en_US'

  // We need to limit the language (i.e. navigator.language) to the Transifex supported languages
  switch (defaultLanguage.toLowerCase().slice(0, 2)) {
    case 'es':
      return 'es_US'
    case 'hy':
      return 'hy_AM'
    case 'ko':
      return 'ko_KR'
    case 'so':
      return 'so'
    case 'vi':
      return 'vi_VN'
    default:
      return 'en_US'
  }
}

/**
 * Retry a function a given number of times with a delay between attempts.
 * @param fn - The function to retry
 * @param attempts - The number of attempts to make
 * @param delay - The delay between attempts in milliseconds
 */
const retry = async <T,>(fn: () => Promise<T>, attempts: number, delay: number): Promise<T> => {
  try {
    return await fn()
  } catch (error) {
    if (attempts <= 1) throw error
    await new Promise((resolve) => setTimeout(resolve, delay))
    return retry(fn, attempts - 1, delay)
  }
}

// Render React Application
enableMocking()
  .then(async () => {
    const rootElement = document.getElementById('root')

    // Wrap the router import in retry logic to prevent intermittent "Module not found" errors
    const routerModule = await retry(
      async () => {
        const mod = await import('./router')

        if (!mod || !('Router' in mod)) {
          throw new Error(`Router module invalid. Available exports: ${mod ? Object.keys(mod).join(', ') : 'none'}`)
        }

        return mod
      },
      3,
      1000,
    )

    const { Router } = routerModule

    // Render React Application
    if (rootElement) {
      createRoot(rootElement).render(<Router />)
    } else {
      throw new Error('Cannot find a root element to mount our React App into')
    }

    // Set initial language
    const currentLocale = initialLanguage()

    // Set the initial Transifex locale with retry
    await retry(() => tx.setCurrentLocale(currentLocale), 3, 1000)
      .then(() => {
        // Set HTML attributes after successful locale change
        document.documentElement.lang = currentLocale.split('_')[0]
      })
      .catch((error) => {
        if (error instanceof Error && error.message.includes('Failed to fetch')) {
          // Ignore the error if the page is unloading (e.g. user navigated away from the page)
          return
        }

        console.error('Error setting initial language', error)
      })
  })
  .catch((error) => {
    console.error('Error starting Patient App', error)
  })

// Configure Instrumentation
initInstrumentation().catch((error) => {
  console.error('Error starting instrumentation', error)
})

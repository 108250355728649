import { isDemoIframe } from './window'

const STORAGE_KEY = 'vital:response'
const STORAGE_KEY_NEW = 'vital'

type LocalStorageKey =
  | `checklist-confirm-${string}`
  | `checklist-touched-${string}`
  | 'cached-languages'
  | 'has-accepted-tests-info-dialog'
  | 'id-token-expiry'
  | 'id-token'
  | 'language'
  | 'refresh-token'
  | 'refresh-token-auth-time'
  | 'user-types'
  | 'visit-type'

/**
 * Add to Local Storage
 */
export const addValueToLocalStorage = <S>(key: LocalStorageKey, value: S) => {
  // use shortlived session storage for demo iframe
  const storage = isDemoIframe() ? sessionStorage : localStorage
  // TODO: Remove this once we migrate to the new storage key (Jan 2025)
  storage.setItem(`${STORAGE_KEY}:${key}`, JSON.stringify(value))
  // Keep this new one!
  storage.setItem(`${STORAGE_KEY_NEW}:${key}`, JSON.stringify(value))
}

/**
 * Retrieve from Local Storage
 */
export const getValueFromLocalStorage = <S>(key: LocalStorageKey) => {
  // use shortlived session storage for demo iframe
  const storage = isDemoIframe() ? sessionStorage : localStorage
  try {
    // TODO: Migrate to the new storage key (Jan 2025)
    const item = storage.getItem(`${STORAGE_KEY}:${key}`)

    return item && item !== null ? (JSON.parse(item) as S) : undefined
  } catch {
    // If error, return undefined
    return undefined
  }
}

/**
 * Returns all keys in storage that start with the STORAGE_KEY
 */
export const getStorageKeys = () => {
  const storage = isDemoIframe() ? sessionStorage : localStorage
  return Object.keys(storage).filter((key) => key.startsWith(STORAGE_KEY_NEW)) as LocalStorageKey[]
}

export function windowNavigateTo(path: string) {
  window.location.replace(path)
}

export function isDemoIframe() {
  // check if instance of self and top are the same - if not prob in an iframe
  return (
    window.self !== window.top &&
    window.location.host === window.parent.location.host &&
    window.parent.location.pathname.startsWith('/demo')
  )
}
